import React from 'react';
import { PageProps } from "gatsby";
import Layout from '../../components/layout/layout';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'en';
  return (
    <Layout language={language} title="About me" description="Some more things about myself" path={props.path} previewimage={previewImage} hasLanguageCounterpart={true}>
      <h1>Imprint</h1>
      <p>
        Manuel Feller<br />
        Gartenfeldstraße 22<br />
        55218 Ingelheim<br />
        Germany<br />
        website@manuel-feller.net<br />
      </p>
      <p>
        I do not allow the use of the contact information provided for legal requirements for sending / submitting not explicitly requested information material and advertising.
        I explicitly reserve the right to take legal actions in case of sending / submitting advertisement information like spam mails or advertisement phone calls.
      </p>
      <h3>Privacy</h3>
      <p>
        There is no place where it is required to enter personal data to use this website.
        There is also no logging or tracking.
      </p>
      <h3>Cookies</h3>
      <p>
        While cookies can be yummy there is currently no need for them.
        That's why you do not need to give your conset for them...
      </p>
    </Layout>
  );
}

